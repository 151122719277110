import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faHome, faCog } from '@fortawesome/free-solid-svg-icons';
import './SupportEngine.css';
import LogViewer from './LogViewer';
import ThemeSwitcher from '../../ThemeSwitcher';
import Settings from './Settings'; // 引入Settings子组件

const SupportEngine = () => {
  const [activeView, setActiveView] = useState('logs'); // 初始为日志视图
  const navigate = useNavigate();

  return (
    <div className="se-container">
      <div className="se-navbar">
        <div className="se-nav-left">
          <button className="se-nav-button" onClick={() => navigate('/')}>
            <FontAwesomeIcon icon={faHome} />
          </button>

          <button
            className={`se-nav-button ${activeView === 'logs' ? 'active' : ''}`}
            onClick={() => setActiveView('logs')}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>

          <button
            className={`se-nav-button ${activeView === 'settings' ? 'active' : ''}`}
            onClick={() => setActiveView('settings')}
          >
            <FontAwesomeIcon icon={faCog} />
          </button>
        </div>
        <div className="se-nav-right">
          <ThemeSwitcher />
        </div>
      </div>

      <div className="se-body">
        {activeView === 'logs' && <LogViewer />}
        {activeView === 'settings' && <Settings />} {/* 添加Settings子组件 */}
      </div>
    </div>
  );
};

export default SupportEngine;
