import React, { useState, useEffect, useRef } from 'react';
import './LogViewer.css';

const LogViewer = () => {
  const [logs, setLogs] = useState('');
  const logAreaRef = useRef(null); // 引用日志区域

  // 将 GMT 时间转换为本地时间的函数
  const convertToLocalTime = (log) => {
    return log.replace(
      /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/g, // 匹配类似 "2024-10-10T08:45:55.043Z" 的时间格式
      (match) => {
        const localTime = new Date(match).toLocaleString(); // 转换为本地时间字符串
        return localTime;
      }
    );
  };

  // 定期获取日志数据
  const fetchLogs = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/support/logs`);
      const data = await response.json();
      const localTimeLogs = convertToLocalTime(data.logs); // 转换日志中的时间
      setLogs(localTimeLogs);
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  };

  // 实时更新日志，每5秒刷新一次
  useEffect(() => {
    fetchLogs(); // 初始加载时获取日志
    const interval = setInterval(fetchLogs, 5000); // 每5秒获取一次日志
    return () => clearInterval(interval); // 清理定时器
  }, []);

  // 当logs更新时，平滑滚动到最下方
  useEffect(() => {
    if (logAreaRef.current) {
      logAreaRef.current.scrollTo({
        top: logAreaRef.current.scrollHeight,
        behavior: 'smooth' // 添加平滑滚动效果
      });
    }
  }, [logs]);

  return (
    <div className="lv-container">
      <div className="lv-header">Log Viewer</div>
      <div className="lv-content" ref={logAreaRef}>
        <pre className="lv-log-area">{logs || 'Fetching logs...'}</pre>
      </div>
    </div>
  );
};

export default LogViewer;
