import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Client } from '@microsoft/microsoft-graph-client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import './AttachmentModal.css';
import { motion, AnimatePresence } from 'framer-motion';

const AttachmentModal = ({ isOpen, emailId, isReplying, onFileChange, onRemoveAttachment, onAttachmentsChange, selectedMailbox, setAttachmentCount, draftAttachments }) => {
  const { instance, accounts } = useMsal();
  const [attachments, setAttachments] = useState([]); // 服务器已有的附件
  const [newAttachments, setNewAttachments] = useState([]); // 本地上传的附件
  const [loadedEmailId, setLoadedEmailId] = useState(null);

  // 加载已有的服务器附件
  useEffect(() => {
    const fetchAttachments = async () => {
      if (!isReplying || !emailId || emailId === loadedEmailId) return;

      try {
        const account = accounts[0];
        const response = await instance.acquireTokenSilent({
          scopes: ['Mail.Read'],
          account: account,
        });

        const client = Client.init({
          authProvider: (done) => {
            done(null, response.accessToken);
          },
        });

        const message = await client.api(`/users/${selectedMailbox}/messages/${emailId}`).expand('attachments').get();
        const regularAttachments = message.attachments.filter(attachment => !attachment.isInline);

        setAttachments(regularAttachments);
        setLoadedEmailId(emailId);
      } catch (error) {
        console.error(error);
      }
    };

    fetchAttachments();
  }, [isReplying, emailId, instance, accounts, loadedEmailId]);

  // 从草稿加载附件（避免重复触发）
  useEffect(() => {
    if (draftAttachments) {
      const newLocalAttachments = draftAttachments.filter(att => att.isNew);
      const existingAttachments = draftAttachments.filter(att => !att.isNew);

      if (newLocalAttachments.length !== newAttachments.length) {
        setNewAttachments(newLocalAttachments);
      }

      if (existingAttachments.length !== attachments.length) {
        setAttachments(existingAttachments);
      }
    }
  }, [draftAttachments]);

  // 合并并传递附件变更
  useEffect(() => {
    const totalAttachments = [...attachments, ...newAttachments];
    onAttachmentsChange(totalAttachments);
    setAttachmentCount(totalAttachments.length);
  }, [attachments, newAttachments]);

  // 处理本地文件上传
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const readerPromises = files.map(file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve({
        name: file.name,
        content: reader.result.split(',')[1], // base64内容
        contentType: file.type,
        size: file.size, // 确保 size 被正确添加
        isNew: true
      });
      reader.onerror = reject;
      reader.readAsDataURL(file);
    }));

    Promise.all(readerPromises).then(results => {
      setNewAttachments([...newAttachments, ...results]);
    }).catch(error => {
      console.error('Failed to read files:', error);
    });
  };

  // 处理附件删除
  const handleRemoveAttachment = (index, isNew) => {
    if (isNew) {
      const updatedNewAttachments = newAttachments.filter((_, i) => i !== index);
      setNewAttachments(updatedNewAttachments);
    } else {
      const updatedAttachments = attachments.filter((_, i) => i !== index);
      setAttachments(updatedAttachments);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    handleFileChange({ target: { files } });
  };

  const totalAttachments = attachments.length + newAttachments.length;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ y: "100%", x: "-50%" }} 
          animate={{ y: 0, x: "-50%" }} 
          exit={{ y: "100%", x: "-50%" }} 
          transition={{ type: 'spring', stiffness: 250, damping: 30 }}
          className="attachment-modal-container"
        >
          <div className="attachment-modal" onDragOver={handleDragOver} onDrop={handleDrop}>
            <div className="attachment-modal-body">
              <div className='attachment-header'>Attachments ({totalAttachments})</div>
              <div className="attachment-list-container">
                <ul className="attachment-list">
                  {attachments.map((attachment, index) => (
                    <li key={index} className="attachment-list-item">
                      <div className="attachment-info">
                        <span className="attachment-name">{attachment.name}</span>
                      </div>
                      <div className="attachment-actions">
                        <a
                          href={`data:${attachment.contentType};base64,${attachment.contentBytes}`}
                          download={attachment.name}
                          className="download-link"
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                        <button onClick={() => handleRemoveAttachment(index, false)} className="remove-button">
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </div>
                    </li>
                  ))}
                  {newAttachments.map((attachment, index) => (
                    <li key={index} className="attachment-list-item new-attachment">
                      <div className="attachment-info">
                        <span className="attachment-name">{attachment.name}</span>
                      </div>
                      <div className="attachment-actions">
                        <a
                          href={`data:${attachment.contentType};base64,${attachment.content}`}
                          download={attachment.name}
                          className="download-link"
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                        <button onClick={() => handleRemoveAttachment(index, true)} className="remove-button">
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                id="file-upload"
                className="file-upload"
              />
              <label htmlFor="file-upload" className="file-upload-label">
                Drag & Drop files here or click to upload
              </label>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AttachmentModal;
