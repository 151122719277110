import React, { useState, useEffect } from 'react';
import './MsgDockApp.css';

const MsgDockApp = () => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchChats();
  }, []);

  const fetchChats = async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/chats'); // 假设API路径为'/api/chats'
      const data = await response.json();
      setChats(data);
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
    setLoading(false);
  };

  const updateReadStatus = async (id, isRead) => {
    try {
      await fetch(`/api/chats/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ isRead: !isRead }),
      });
      fetchChats();  // 刷新数据
    } catch (error) {
      console.error('Error updating chat status:', error);
    }
  };

  if (loading) {
    return <div className="msgdock-loading">Loading chats...</div>;
  }

  return (
    <div className="msgdock-container">
      <div className="msgdock-header">
        <h2>Message Summaries</h2>
      </div>
      <div className="msgdock-list">
        {chats.length === 0 ? (
          <div className="msgdock-no-chats">No chats found</div>
        ) : (
          chats.map(chat => (
            <div key={chat.id} className="msgdock-item">
              <div className="msgdock-info">
                <span className="msgdock-name">{chat.name}</span>
                <span className="msgdock-email">{chat.email}</span>
                <span className={`msgdock-status ${chat.isRead ? 'read' : 'unread'}`}>
                  {chat.isRead ? 'Read' : 'Unread'}
                </span>
              </div>
              <button
                className="msgdock-toggle"
                onClick={() => updateReadStatus(chat.id, chat.isRead)}
              >
                Mark as {chat.isRead ? 'Unread' : 'Read'}
              </button>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default MsgDockApp;
