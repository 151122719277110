import React from 'react';

const TypekeyApp = () => {
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', boxSizing: 'border-box' }}>
      <iframe 
        src="https://fraba-support.eastus.cloudapp.azure.com/typekey"
        title="Typekey App"
        style={{ width: '96%', height: '88%', border: 'none', boxSizing: 'border-box', overflow: 'hidden' }}
        allowFullScreen
      />
    </div>
  );
};

export default TypekeyApp;
