import signatureImageData from '../signatureImage.json';

const ComposeMailSignatures = ({ isReplying, email, selectedMailbox, subject, userName }) => {
  console.log('ComposeEmail received props:', { email, selectedMailbox, isReplying });
  const generateSignature = () => {
    const signature = `
      <br/>
      <br/>
      Best Regards,<br/>
      <br/>
      ${userName}<br/>
      <br/>
      <img src="${signatureImageData.image}" alt="Signature" style="height: 50px;"> <br/><br/>
    `;
    
    if (isReplying) {
      const formattedDate = new Date().toLocaleString();
      const replySignature = `
        <hr/>
        <strong>From:</strong> ${selectedMailbox}<br/>
        <strong>Sent:</strong> ${formattedDate}<br/>
        <strong>To:</strong> ${email.from.emailAddress.name} &lt;${email.from.emailAddress.address}&gt;<br/>
        <strong>Subject:</strong> ${subject || `Re: ${email.subject}`}<br/><br/>
        <small>This e-mail is replied by using Support Portal, it may contain confidential and/or privileged information. If you are not the intended recipient (or have received this e-mail in error) please notify the sender immediately and destroy this e-mail. Any unauthorized copying, disclosure or distribution of the material in this e-mail is strictly forbidden.</small>
        <br/>
        <small>------------------------------------------------</small>
      `;
      return signature + replySignature;
    } else {
      return signature;
    }
  };

  return generateSignature();
};



export default ComposeMailSignatures;
