import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import './AppsPage.css';
import UserFetcher from './UserFetcher';
import ThemeSwitcher from './ThemeSwitcher';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faSearch, faComment, faRocket, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'; // 添加新的图标

const apps = [
  { name: 'Mail', icon: faEnvelope },
  { name: 'MessageBox', icon: faComment }, 
  { name: 'Typekey+', icon: faSearch }, 
  { name: 'Automation', icon: faRocket }, 
];

const AppsPage = ({ handleAppClick }) => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [selectedApp, setSelectedApp] = useState(null);
  const userName = useSelector((state) => state.user.name);
  const userAvatar = useSelector((state) => state.user.avatar);
  const theme = useSelector((state) => state.theme);
  const [avatarLoaded, setAvatarLoaded] = useState(false);

  useEffect(() => {
    if (userName && (userAvatar || avatarLoaded)) {
      // 用户名和头像逻辑
    }
  }, [userName, userAvatar, avatarLoaded]);

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  const handleAvatarLoad = () => {
    setAvatarLoaded(true);
  };

  const getCurrentGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 6) return "It's midnight,";
    if (currentHour < 12) return "Good morning,";
    if (currentHour < 18) return "Good afternoon,";
    return "Good evening,";
  };

  const internalHandleAppClick = (appName) => {
    setSelectedApp(appName);
    if (handleAppClick) {
      handleAppClick(appName);
    }

    if (appName === 'Mail') {
      navigate('/mail');
    } else if (appName === 'Typekey+') {
      window.open('https://fraba-support.eastus.cloudapp.azure.com/typekey', '_blank');
    } else if (appName === 'MessageBox') {
      navigate('/messagebox'); 
    } else if (appName === 'Automation') {
      navigate('/mail-automation'); 
    }
  };

  const handleLogout = () => {
    instance.logoutPopup().catch(e => {
      console.error(e);
    });
  };

  const logoSrc = theme === 'dark' ? './posital-white.png' : './posital.png';

  return (
    <div className={`apps-page ${avatarLoaded || !userAvatar ? 'fade-in' : ''}`} style={{ visibility: 'visible' }}>
      <UserFetcher />
      <header className="header">
        <div className="top-left-container">
          <img src={logoSrc} alt="Posital" className="posital-logo" />
          <div className="support-portal-text">Support Portal v2.0.7 20241030</div>
        </div>
        <div className="top-right-container">
          <ThemeSwitcher />
          <button onClick={handleLogout} className="logout-button">
            <FontAwesomeIcon icon={faSignOutAlt} />
          </button>
        </div>
        {userName && (
          <div className="greeting">
            {userAvatar ? (
              <img
                src={userAvatar}
                alt="User Avatar"
                className="avatar"
                onLoad={handleAvatarLoad}
              />
            ) : (
              <div className="placeholder-avatar">No Avatar</div> // 提供一个占位符或默认头像
            )}
            <div>
              {getCurrentGreeting()} <strong>{userName}</strong>
            </div>
          </div>
        )}
      </header>
      <div className="notification">
        <p>
          If you need to access the old Support Portal, please 
          click <a href="https://fraba-support.eastus.cloudapp.azure.com/" target="_blank" rel="noopener noreferrer" className="link">here</a>. 
          Note that Typekey tool and chatbot transcripts can only be processed in the old support portal. The new ones are under development.
        </p>
      </div>
      <div className="apps-grid">
        {apps.map((app, index) => (
          <div
            key={index}
            className="app-container"
            onClick={() => internalHandleAppClick(app.name)}
          >
            <div className="app-tile">
              <FontAwesomeIcon icon={app.icon} className="app-icon" />
            </div>
            <div className="app-name">{app.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
  
};

export default AppsPage;
