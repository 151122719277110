import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';  
import ComposeMailLoader from './email-compose/ComposeMailLoader';
import ComposeMailSignatures from './email-compose/ComposeMailSignatures';
import ComposeMailSender from './email-compose/ComposeMailSender';
import ComposeMailAttachments from './email-compose/ComposeMailAttachments';
import AttachmentModal from './email-compose/AttachmentModal';
import DraftBox from './email-compose/DraftBox'; 
import EmailEditor from './email-compose/EmailEditor';
import ComposeAddressBook from './email-compose/ComposeAddressBook'; 
import './ComposeEmail.css';

const ComposeEmail = forwardRef(({ onClose, isReplying, email, isAttachmentModalOpen, setIsAttachmentModalOpen, selectedMailbox, onSendStatusChange, setAttachmentCount, isDraftBoxOpen, setIsDraftBoxOpen }, ref) => {
  const theme = useSelector(state => state.theme);
  const userEmail = useSelector(state => state.user.email);
  const userName = useSelector((state) => state.user.name);
  const [to, setTo] = useState('');
  const [cc, setCc] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [attachments, setAttachments] = useState([]); // 用于存储所有附件
  const [conversationId, setConversationId] = useState('');

  const toInputRef = useRef(null);
  const ccInputRef = useRef(null);

  const [isAddressBookOpen, setIsAddressBookOpen] = useState(false); 
  const [targetInput, setTargetInput] = useState(''); 

  const mailboxAddress = selectedMailbox === 'Support' 
    ? 'support-pool@fraba.com' 
    : (selectedMailbox === 'Personal' ? userEmail : selectedMailbox);

  const handleLoadComplete = ({ content, attachments, conversationId, subject, fromAddress, ccAddresses }) => {
    const signature = ComposeMailSignatures({
      isReplying,
      email,
      selectedMailbox: mailboxAddress,
      subject,
      userName,
    });

    setBody(signature + content);
    setAttachments(attachments); // 设定附件
    setConversationId(conversationId);
    if (isReplying) {
      setTo(fromAddress); 
      setCc(ccAddresses.join(', '));
      setSubject(subject);
    }
  };

  const handleError = () => {
    // Handle errors here
  };

  const mailSender = ComposeMailSender({
    to,
    cc,
    subject,
    body,
    attachments,
    email,
    isReplying,
    selectedMailbox: mailboxAddress,
    conversationId,
    onSendStatusChange,
    onClose,
  });

  useImperativeHandle(ref, () => ({
    handleSend: mailSender.handleSend,
  }));

  const mailAttachments = ComposeMailAttachments({
    attachments,
    setAttachments,
  });

  // 获取当前草稿
  const currentDraft = {
    subject,
    to,
    cc,
    body,
    attachments, // 保存当前附件
  };

  const openAddressBook = (inputType) => {
    setTargetInput(inputType);
    setIsAddressBookOpen(true);
  };

  const closeAddressBook = () => {
    setIsAddressBookOpen(false);
  };

  const handleBodyClick = () => {
    closeAddressBook();
  };

  const handleSelectContact = (email) => {
    if (targetInput === 'to') {
      setTo((prev) => (prev ? `${prev}, ${email}` : email));
    } else if (targetInput === 'cc') {
      setCc((prev) => (prev ? `${prev}, ${email}` : email));
    }
    setIsAddressBookOpen(false);
  };

  // 应用选中的草稿
  const applyDraft = (draft) => {
    const restoredAttachments = draft.attachments.map(att => {
      if (att.isNew) {
        // 本地上传的附件，保留 base64 或 Blob 数据
        return {
          name: att.name,
          contentType: att.contentType,
          content: att.content, // base64 或 Blob 数据
          isNew: true,
        };
      } else {
        // 已有的附件，直接使用元数据
        return {
          name: att.name,
          contentType: att.contentType,
          contentBytes: att.contentBytes, // 需要时下载
          isNew: false,
        };
      }
    });
  
    setSubject(draft.subject || '');
    setTo(draft.to || '');
    setCc(draft.cc || '');
    setBody(draft.body || '');
    setAttachments(restoredAttachments); // 更新附件
  };
  

  return (
    <motion.div
      className="cm2-compose-email"
      initial={{ y: "100%", x: "-50%" }} 
      animate={{ y: 0, x: "-50%" }} 
      exit={{ y: "100%", x: "-50%" }} 
      transition={{ type: 'spring', stiffness: 250, damping: 30 }}
    >
      <ComposeMailLoader 
        email={email} 
        selectedMailbox={mailboxAddress} 
        isReplying={isReplying} 
        onLoadComplete={handleLoadComplete} 
        onError={handleError} 
      />
      
      <div className="cm2-compose-fields">
        <div className="cm2-compose-field">
          <label>SUBJECT</label>
          <input
            type="text"
            value={subject}
            onClick={handleBodyClick}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>
        <div className="cm2-compose-field">
        <label>TO</label>
        <input
          type="email"
          ref={toInputRef}
          value={to}
          onClick={() => openAddressBook('to')}
          onChange={(e) => setTo(e.target.value)}
        />
      </div>
      <div className="cm2-compose-field">
        <label>CC</label>
        <input
          type="text"
          ref={ccInputRef}
          value={cc}
          onClick={() => openAddressBook('cc')}
          onChange={(e) => setCc(e.target.value)}
        />
      </div>
      <AnimatePresence>
        {isAddressBookOpen && (
          <ComposeAddressBook 
            onSelect={handleSelectContact} 
            targetInput={targetInput} 
          />
        )}
      </AnimatePresence>
      </div>
      <div className="cm2-compose-body" onMouseOver={handleBodyClick}>
        <EmailEditor body={body} setBody={setBody}  theme={theme} />
      </div>
      
      {/* 将附件传递给 AttachmentModal */}
      <AttachmentModal
        isOpen={isAttachmentModalOpen}
        emailId={email?.id}
        isReplying={isReplying}
        onClose={() => setIsAttachmentModalOpen(false)}
        onFileChange={mailAttachments.handleFileChange}
        onRemoveAttachment={mailAttachments.handleRemoveAttachment}
        onAttachmentsChange={setAttachments} // 传递更新后的附件列表
        selectedMailbox={mailboxAddress}
        setAttachmentCount={setAttachmentCount}
        draftAttachments={attachments} // 传递附件给 AttachmentModal
      />

      <DraftBox 
        isOpen={isDraftBoxOpen} 
        currentDraft={currentDraft} 
        onApplyDraft={applyDraft} 
      />
    </motion.div>
  );
});

export default ComposeEmail;