const DB_NAME = 'DraftDB';
const DB_VERSION = 1;
const STORE_NAME = 'drafts';

// 打开 IndexedDB 数据库
export function openDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = function (event) {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
      }
    };

    request.onsuccess = function (event) {
      resolve(event.target.result);
    };

    request.onerror = function (event) {
      reject(event.target.error);
    };
  });
}


// 添加草稿到 IndexedDB
export function addDraft(draft) {
    return openDB().then(db => {
      return new Promise((resolve, reject) => {
        const transaction = db.transaction([STORE_NAME], 'readwrite');
        const store = transaction.objectStore(STORE_NAME);
  
        const updatedDraft = {
          ...draft,
          createdAt: new Date().toISOString(), // 将日期转换为 ISO 字符串保存
          attachments: draft.attachments.map(file => {
            if (file.isNew) {
              return {
                name: file.name,
                type: file.contentType,
                size: file.size,
                content: file.content, // base64 或 Blob
                isNew: true,
              };
            } else {
              return {
                name: file.name,
                contentType: file.contentType,
                contentBytes: file.contentBytes, // 可选：如果有contentBytes
                isNew: false,
              };
            }
          })
        };
  
        const request = store.add(updatedDraft);
  
        request.onsuccess = () => {
          resolve(request.result); // 返回新增草稿的 ID
        };
  
        request.onerror = (event) => {
          reject(event.target.error);
        };
      });
    });
  }
  
  

// 获取所有草稿
export function getDrafts() {
  return openDB().then(db => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([STORE_NAME], 'readonly');
      const store = transaction.objectStore(STORE_NAME);
      const request = store.getAll();

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  });
}

// 获取单个草稿
export function getDraft(id) {
  return openDB().then(db => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([STORE_NAME], 'readonly');
      const store = transaction.objectStore(STORE_NAME);
      const request = store.get(id);

      request.onsuccess = (event) => {
        const draft = event.target.result;

        const attachments = draft.attachments.map(att => {
          if (att.isNew) {
            // 本地上传的附件，恢复为 File 对象
            return {
              name: att.name,
              contentType: att.contentType,
              size: att.size,
              content: att.content, // base64 或 Blob
              isNew: true,
            };
          } else {
            // 已有的附件，只需要元数据，不存储实际文件
            return {
              name: att.name,
              contentType: att.contentType,
              contentBytes: att.contentBytes,
              isNew: false,
            };
          }
        });

        resolve({ ...draft, attachments });
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  });
}

// 删除草稿
export function deleteDraft(id) {
  return openDB().then(db => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([STORE_NAME], 'readwrite');
      const store = transaction.objectStore(STORE_NAME);
      const request = store.delete(id);

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  });
}

// 更新草稿
export function updateDraft(draft) {
  return openDB().then(db => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([STORE_NAME], 'readwrite');
      const store = transaction.objectStore(STORE_NAME);
      const request = store.put(draft);

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  });
}
