// src/components/auth/AuthService.js
import { msalInstance } from './authConfig';
import axios from 'axios';
import store from '../../store'; // 导入 store

// 发送 accessToken 到后端
const setAccessTokenToBackend = async (accessToken) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/setAccessToken`, { accessToken });
        if (response.status !== 200) {
            throw new Error('Failed to set access token to backend');
        }
    } catch (error) {
        console.error('Error setting access token to backend:', error);
    }
};

// 发送用户详情到后端
const sendUserDetailsToBackend = async (retryCount = 0) => {
    // 一开始等待 1 秒再执行
    await new Promise(resolve => setTimeout(resolve, 1000));

    const state = store.getState();
    const username = state.user.name;
    const email = state.user.email;

    if (username && email) {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/setUserDetails`, {
                username,
                email,
            });
            if (response.status !== 200) {
                throw new Error('Failed to set user details to backend');
            }
        } catch (error) {
            console.error('Error setting user details to backend:', error);
        }
    } else {
        console.log('Username or email is null, skipping sending user details to backend.');

        if (retryCount < 3) { // 最多重试 3 次
            const delay = [500, 1000, 2000][retryCount]; // 重试延迟分别为 500ms, 1000ms, 2000ms
            console.log(`Retrying in ${delay} ms...`);
            setTimeout(() => sendUserDetailsToBackend(retryCount + 1), delay);
        } else {
            console.log('Max retries reached, not sending user details to backend.');
        }
    }
};

// 自动刷新令牌的函数
const autoRefreshToken = async () => {
    try {
        const account = msalInstance.getAllAccounts()[0];
        if (account) {
            const response = await msalInstance.acquireTokenSilent({
                scopes: ['User.Read'], // 使用一个轻量级的 scope 来请求令牌
                account: account,
            });
            if (response.accessToken) {
                setAccessTokenToBackend(response.accessToken);
                console.log('Access token refreshed successfully');
            }
        }
    } catch (error) {
        console.error('Error refreshing access token:', error);
    }
};

// 启动定时器每10分钟刷新令牌
export const startAutoRefreshToken = () => {
    autoRefreshToken(); // 立即刷新一次
    setInterval(autoRefreshToken, 10 * 60 * 1000); // 每10分钟刷新一次
};

// MSAL 事件监听
msalInstance.addEventCallback((event) => {
    if (event.eventType === 'msal:acquireTokenSuccess' && event.payload.accessToken) {
        setAccessTokenToBackend(event.payload.accessToken)
            .then(() => {
                sendUserDetailsToBackend(); // 发送用户信息到后端
            });
    }
    if (event.eventType === 'msal:loginSuccess' && event.payload.accessToken) {
        setAccessTokenToBackend(event.payload.accessToken)
            .then(() => {
                sendUserDetailsToBackend(); // 发送用户信息到后端
            });
    }
});

export { setAccessTokenToBackend, sendUserDetailsToBackend };
