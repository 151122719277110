import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import AutoReplyTemplate from './AutoReplyTemplate';
import './Settings.css';

const Settings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const openModal = (title) => {
    setModalTitle(title);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalTitle('');
  };

  return (
    <div className="se-settings-container">
      <h1 className="se-settings-title">Settings</h1>
      <div className="se-settings-item" onClick={() => openModal('Auto-Reply Template')}>
        Auto-Reply Template
      </div>


      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            className="se-modal-overlay"
            onClick={closeModal}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <motion.div
              className="se-modal-content"
              onClick={(e) => e.stopPropagation()}
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              {/* 固定的标题和关闭按钮 */}
              <div className="se-modal-header">
                <h2 className="se-modal-title">{modalTitle}</h2>
                <button className="se-ar-close-button" onClick={closeModal}>✕</button>
              </div>

              {/* 可滚动的内容区域 */}
              <div className="se-modal-body">
                <AutoReplyTemplate onClose={closeModal} />
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Settings;
