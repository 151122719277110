import React, { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import Window from './Window';
import './WindowManager.css';

const WindowManager = ({ selectedEmail, openApps, onCloseApp, selectedMailbox }) => {
  const [openWindows, setOpenWindows] = useState([]);
  const [topZIndex, setTopZIndex] = useState(2000);

  // 确保只在第一次打开应用时添加窗口
  useEffect(() => {
    openApps.forEach((app) => {
      const existingWindow = openWindows.find(window => window.id === app.id);
      if (!existingWindow) {
        setOpenWindows(prevWindows => [...prevWindows, { ...app, zIndex: topZIndex }]);
        setTopZIndex((prev) => prev + 1);
      }
    });
  }, [openApps, openWindows, topZIndex]);

  const bringWindowToFront = (id) => {
    setTopZIndex(topZIndex + 1);
    setOpenWindows((prevWindows) =>
      prevWindows.map((window) =>
        window.id === id ? { ...window, zIndex: topZIndex } : window
      )
    );
  };

  const closeAppWindow = (id) => {
    // 从 openWindows 中移除
    setOpenWindows(prevWindows => prevWindows.filter(window => window.id !== id));
    // 同时调用 onCloseApp 更新 openApps
    onCloseApp(id);
  };

  return (
    <div className="wm-windows">
      <AnimatePresence> {/* 包裹在 AnimatePresence 中 */}
        {openWindows.map((app) => (
          <Window
            key={app.id}
            app={app}
            selectedEmail={selectedEmail}
            selectedMailbox={selectedMailbox}
            zIndex={app.zIndex}
            bringToFront={() => bringWindowToFront(app.id)}
            closeWindow={() => closeAppWindow(app.id)} // 传递关闭窗口的函数
          />
        ))}
      </AnimatePresence>
    </div>
  );
};

export default WindowManager;
