import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './Window.css';
import TypekeyApp from './TypekeyApp';
import AiAssistantApp from './Ai-AssistantApp';
import MsgDockApp from './MsgDockApp';
import CalendarApp from './CalendarApp';

const Window = ({ app, zIndex, bringToFront, closeWindow, selectedEmail, selectedMailbox }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

  // 根据 app.id 动态设置窗口的宽度和高度
  const getWindowSize = () => {
    switch (app.id) {
      case 'typekey':
        return { width: '800px', height: '600px' }; // TypekeyApp 较大
      case 'ai':
        return { width: '500px', height: '600px' }; // AiAssistantApp 大小
      case 'calendar':
        return { width: '500px', height: '600px' }; // AddressBookApp 中等
      case 'msgbox':
        return { width: '500px', height: '600px' }; 
      default:
        return { width: '500px', height: '400px' }; // 默认大小
    }
  };

  const { width, height } = getWindowSize();

  // 动态计算初始位置，确保窗口在页面中居中显示
  useEffect(() => {
    const initialX = (window.innerWidth - parseInt(width)) / 2;
    const initialY = (window.innerHeight - parseInt(height)) / 2;
    setPosition({ x: initialX, y: initialY });
  }, [width, height]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setDragOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const newX = e.clientX - dragOffset.x;
      const newY = e.clientY - dragOffset.y;
      setPosition({ x: newX, y: newY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // 根据 app.id 动态渲染应用内容
  const renderAppContent = () => {
    switch (app.id) {
      case 'typekey':
        return <TypekeyApp />;
      case 'ai':
        return <AiAssistantApp selectedEmail={selectedEmail} selectedMailbox={selectedMailbox} />; 
      case 'calendar':
        return <CalendarApp />;
      case 'msgbox':
        return <MsgDockApp />;
      default:
        return <div>Unknown App</div>;
    }
  };

  return (
    <motion.div
      className="uaw-window"
      style={{ top: position.y, left: position.x, zIndex, width, height }} // 动态宽度和高度
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.15 }}
      onMouseDown={bringToFront}
    >
      <div
        className="uaw-window-header"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        style={{ cursor: 'grab' }}
      >
        <span>{app.name}</span>
        <button className="uaw-close-btn" onClick={closeWindow}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div className="uaw-window-content" style={{ height: '100%', width: '100%', display: 'flex' }}>
        {renderAppContent()}
      </div>
    </motion.div>
  );
};

export default Window;
