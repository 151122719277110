import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faRobot, faCalendar, faMessage } from '@fortawesome/free-solid-svg-icons';
import WindowManager from './utilities/WindowManager';
import './UtilityArea.css';

const initialApps = [
  { id: 'ai', name: 'AI Copilot', icon: faRobot },
  { id: 'typekey', name: 'Typekey+', icon: faSearch },
  { id: 'calendar', name: 'Calendar', icon: faCalendar },
 // { id: 'msgbox', name: 'MsgBox Transcripts', icon: faMessage },
];

const UtilityArea = ({ selectedEmail, selectedMailbox }) => {
  const [openApps, setOpenApps] = useState([]);

  const handleAppClick = (app) => {
    const isAppOpen = openApps.some(openApp => openApp.id === app.id);
    if (!isAppOpen) {
      setOpenApps([...openApps, app]);
    }
  };

  const handleCloseApp = (id) => {
    setOpenApps(openApps.filter(app => app.id !== id));
  };

  return (
    <div className="ua-container">
      
      <div className="ua-apps">
        {initialApps.map((app) => (
          <button key={app.id} onClick={() => handleAppClick(app)}>
            <FontAwesomeIcon icon={app.icon} className="ua-app-icon" />
            {app.name}
          </button>
        ))}
      </div>
      <WindowManager selectedEmail={selectedEmail} selectedMailbox={selectedMailbox} openApps={openApps} onCloseApp={handleCloseApp} />
    </div>
  );
};

export default UtilityArea;
