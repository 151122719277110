import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './DraftBox.css';
import { getDrafts, addDraft, deleteDraft } from './indexedDBHelper'; // 引入 IndexedDB helper

const DraftBox = ({ isOpen, onApplyDraft, currentDraft }) => {
  const [drafts, setDrafts] = useState([]);

  useEffect(() => {
    // 从 IndexedDB 加载所有草稿
    getDrafts().then(loadedDrafts => {
      setDrafts(loadedDrafts);
    });
  }, []);

  const handleSaveDraft = () => {
    if (currentDraft) {
      addDraft(currentDraft).then((id) => {
        const updatedDraft = { 
          ...currentDraft, 
          id, 
          createdAt: new Date().toISOString()  // 在本地立即更新时间
        };
        setDrafts([...drafts, updatedDraft]); // 更新草稿列表
      });
    }
  };
  
  

  const handleDeleteDraft = (id) => {
    deleteDraft(id).then(() => {
      setDrafts(drafts.filter(draft => draft.id !== id)); // 从列表中移除已删除草稿
    });
  };

  return (
    <motion.div
      className="db-draftbox-container"
      initial={{ y: '100%', x: '-50%' }} 
      animate={{ y: isOpen ? 0 : '100%', x: '-50%' }} 
      exit={{ y: '100%', x: '-50%' }} 
      transition={{ type: 'spring', stiffness: 250, damping: 30 }}
    >
      <div className="db-draftbox-header">Saved Drafts</div>
      
      <div className="db-draftbox-content">
        <div className="db-draft-list">
          {drafts.length === 0 ? (
            <div className="db-draft-empty">No drafts available.</div>
          ) : (
            drafts.map((draft, index) => (
              <div key={index} className="db-draft-item">
                <div className="db-draft-info">
                  <div className="db-draft-text">
                  <div className="db-draft-subject">
                    {draft.subject ? 
                      (draft.subject.length > 39 ? draft.subject.substring(0, 39) + '...' : draft.subject) 
                      : 'Untitled Draft'}
                  </div>
                    <div className="db-draft-time">
                      {draft.createdAt ? new Date(draft.createdAt).toLocaleString() : 'Unknown time'} {/* 转换时间 */}
                    </div>
                  </div>
                  <div className="db-draft-actions">
                    <button className="db-draft-btn apply-btn" onClick={() => onApplyDraft(draft)}>
                      Apply
                    </button>
                    <button className="db-draft-btn delete-btn" onClick={() => handleDeleteDraft(draft.id)}>
                      Delete
                    </button>
                  </div>
                </div>
              </div>

            ))
          )}
        </div>
      </div>

      <div className="db-draftbox-footer">
        <button className="db-draftbox-btn" onClick={handleSaveDraft}>
          Save Current Draft
        </button>
      </div>
    </motion.div>
  );
};

export default DraftBox;
