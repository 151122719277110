import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Client } from '@microsoft/microsoft-graph-client';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CalendarApp.css';

const Calendar = ({ className }) => {
  const { instance, accounts } = useMsal();
  const userEmail = useSelector(state => state.user.email);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  // 按天获取日历事件
  const fetchCalendarEvents = async (selectedDate) => {
    setIsLoading(true);
    setCalendarEvents([]);
    try {
      const account = accounts[0];
      const response = await instance.acquireTokenSilent({
        scopes: ['Calendars.Read', 'Calendars.Read.Shared'],
        account: account,
      });

      const client = Client.init({
        authProvider: (done) => {
          done(null, response.accessToken);
        },
      });

      const startOfDay = new Date(selectedDate.setHours(0, 0, 0, 0));
      const endOfDay = new Date(selectedDate.setHours(23, 59, 59, 999));

      // 获取一天中的所有事件，包括被邀请的会议
      const events = await client
        .api(`/me/calendarView`)
        .query({
          startDateTime: startOfDay.toISOString(),
          endDateTime: endOfDay.toISOString(),
        })
        .orderby('start/dateTime ASC')
        .get();

      setCalendarEvents(events.value);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCalendarEvents(selectedDate);
  }, [selectedDate]);

  // 点击事件处理函数，用于打开Teams会议
  const handleEventClick = (event) => {
    const { onlineMeeting, webLink } = event;
    if (onlineMeeting && onlineMeeting.joinUrl) {
      window.open(onlineMeeting.joinUrl, '_blank');
    } else if (webLink) {
      window.open(webLink, '_blank');
    } else {
      alert('This event does not have an online meeting link or a web link.');
    }
  };

  return (
    <div className={`ca-app-calendar-container ${className}`}>
      <div className="ca-app-calendar-header">
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="yyyy-MM-dd"
          className="ca-app-calendar-datepicker"
        />
      </div>

      {isLoading ? (
        <div className="ca-app-calendar-loading">Loading events...</div>
      ) : (
        <div className="ca-app-calendar-events">
          {calendarEvents.length === 0 ? (
            <div className="ca-app-no-events">No events for this day</div>
          ) : (
            calendarEvents.map(event => {
              console.log("Event details:", event); // 调试输出每个事件的详细信息
              return (
                <div 
                  key={event.id} 
                  className="ca-app-calendar-event" 
                  onClick={() => handleEventClick(event)} // 点击事件
                  style={{ cursor: event.onlineMeeting ? 'pointer' : 'default' }} // 如果有会议链接，显示指针
                >
                  <div className="ca-app-event-subject">{event.subject}</div>
                  <div className="ca-app-event-time">
                    {new Date(event.start.dateTime).toLocaleTimeString('en-US', { 
                      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, 
                      hour: '2-digit', 
                      minute: '2-digit' 
                    })} 
                    - 
                    {new Date(event.end.dateTime).toLocaleTimeString('en-US', { 
                      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, 
                      hour: '2-digit', 
                      minute: '2-digit' 
                    })}
                  </div>


                  <div className="ca-app-event-location">{event.location.displayName || 'Online'}</div>
                </div>
              );
            })
          )}
        </div>
      )}
    </div>
  );
};

export default Calendar;
