import { useMsal } from '@azure/msal-react';
import { Client } from '@microsoft/microsoft-graph-client';
import { useSelector } from 'react-redux';
import mailboxRules from './mailboxRules';

const ComposeMailSender = ({ to, cc, subject, body, attachments, email, isReplying, selectedMailbox, conversationId, onSendStatusChange, onClose }) => {
  const { instance, accounts } = useMsal();
  const userName = useSelector((state) => state.user.name);

  const handleSend = async () => {
    if (!to) {
      onSendStatusChange('error');
      return;
    }

    onSendStatusChange('sending');

    try {
      const account = accounts[0];
      const response = await instance.acquireTokenSilent({
        scopes: ['Mail.Send', 'Mail.ReadWrite'],
        account: account,
      });

      const client = Client.init({
        authProvider: (done) => {
          done(null, response.accessToken);
        },
      });

      const emailSubject = subject;

      // 添加样式：在邮件内容中添加统一的字体、行距、大小等样式
      const styledBody = `
        <div style="font-family: Arial, sans-serif; font-size: 14px; line-height: 1.5; color: #000;">
          ${body}
        </div>
      `;

      const toRecipients = to.split(',').filter(email => email.trim() !== '').map(email => ({
        emailAddress: {
          address: email.trim(),
        },
      }));

      const ccRecipients = cc.split(',').filter(email => email.trim() !== '').map(email => ({
        emailAddress: {
          address: email.trim(),
        },
      }));

      if (isReplying) {
        const message = {
          message: {
            subject: emailSubject,
            body: {
              contentType: 'HTML',
              content: styledBody,  // 使用应用了样式的body
            },
            toRecipients: toRecipients,
            ccRecipients: ccRecipients,
            attachments: attachments.map(attachment => ({
              '@odata.type': '#microsoft.graph.fileAttachment',
              name: attachment.name,
              contentBytes: attachment.content || attachment.contentBytes,  // 确保这是有效的 base64
              contentType: attachment.contentType,
            })),                 
            conversationId: conversationId,
            categories: [`Assigned to ${userName}`],
          },
          comment: ''
        };

        await client.api(`/users/${selectedMailbox}/messages/${email.id}/reply`).post(message);
      } else {
        const message = {
          subject: emailSubject,
          body: {
            contentType: 'HTML',
            content: styledBody,  // 使用应用了样式的body
          },
          toRecipients: toRecipients,
          ccRecipients: ccRecipients,
          attachments: attachments.map(attachment => ({
            '@odata.type': '#microsoft.graph.fileAttachment',
            name: attachment.name,
            contentBytes: attachment.content || attachment.contentBytes,  // 确保这是有效的 base64
            contentType: attachment.contentType,
          })),
        };
        await client.api(`/users/${selectedMailbox}/sendMail`).post({
          message: message,
          saveToSentItems: "true"
        });
      }

      onSendStatusChange('success');

      setTimeout(() => {
        onClose();
      }, 1500);

    } catch (error) {
      console.error('Error sending email:', error);
      onSendStatusChange('error');
    }
  };

  return { handleSend };
};

export default ComposeMailSender;
