import React, { useEffect, useState, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import { Client } from '@microsoft/microsoft-graph-client';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import AttachmentPreview from './email-detail/AttachmentPreview';
import EmailDetailPersonnel from './email-detail/EmailDetailPersonnel';
import EmailDetailAttachments from './email-detail/EmailDetailAttachments';
import './EmailDetail.css';

const EmailDetail = ({ email, setLoading, selectedMailbox, className }) => {
  const { instance, accounts } = useMsal();
  const theme = useSelector(state => state.theme);
  const userEmail = useSelector(state => state.user.email); 
  const userName = useSelector(state => state.user.name);
  const [emailContent, setEmailContent] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [previewAttachmentIndex, setPreviewAttachmentIndex] = useState(null);
  const [showFullRecipients, setShowFullRecipients] = useState(false);
  const iframeRef = useRef(null);

  const fetchEmailContent = async () => {
    if (email && selectedMailbox) {
      setIsLoading(true);
      setEmailContent('');
      setAttachments([]);
      try {
        const account = accounts[0];
        const response = await instance.acquireTokenSilent({
          scopes: ['Mail.Read', 'Mail.Read.Shared'],
          account: account,
        });

        const client = Client.init({
          authProvider: (done) => {
            done(null, response.accessToken);
          },
        });

        const userMailbox = selectedMailbox === 'Support' 
          ? 'support-pool@fraba.com' 
          : (selectedMailbox === 'Personal' ? userEmail : selectedMailbox);

        const message = await client.api(`/users/${userMailbox}/messages/${email.id}`).expand('attachments').get();

        if (!message.isRead) {
          await client.api(`/users/${userMailbox}/messages/${email.id}`).update({
            isRead: true
          });
        }

        const inlineAttachments = message.attachments.filter(attachment => attachment.isInline);
        const regularAttachments = message.attachments.filter(attachment => !attachment.isInline);

        let content = message.body.content;
        inlineAttachments.forEach((attachment) => {
          const contentId = `cid:${attachment.contentId}`;
          const attachmentUrl = `data:${attachment.contentType};base64,${attachment.contentBytes}`;
          content = content.replace(new RegExp(contentId, 'g'), attachmentUrl);
        });

        setEmailContent(content);
        setAttachments(regularAttachments);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }
  };

  const handleEmailStatusUpdate = async () => {
    if (!email) return;

    const account = accounts[0];
    const response = await instance.acquireTokenSilent({
      scopes: ['Mail.ReadWrite'],
      account: account,
    });

    const client = Client.init({
      authProvider: (done) => {
        done(null, response.accessToken);
      },
    });

    try {
      if (selectedMailbox === 'Support' && userName) {
        const userMailbox = 'support-pool@fraba.com';
        const message = await client.api(`/users/${userMailbox}/messages/${email.id}`).get();
        const currentCategories = message.categories || [];
        const newCategory = `Seen by ${userName}`;

        if (!currentCategories.includes(newCategory)) {
          const updatedCategories = [...currentCategories, newCategory];
          await client.api(`/users/${userMailbox}/messages/${email.id}`).update({ categories: updatedCategories });
        }
      } else if (selectedMailbox === 'Personal') {
        const userMailbox = userEmail;
        await client.api(`/users/${userMailbox}/messages/${email.id}`).update({ isRead: true });
      }
    } catch (error) {
      console.error('Error updating email status:', error);
    }
  };

  const handleDownloadEmail = async () => {
    if (!email) return;

    const account = accounts[0];
    const response = await instance.acquireTokenSilent({
      scopes: ['Mail.Read', 'Mail.Read.Shared'],
      account: account,
    });

    const client = Client.init({
      authProvider: (done) => {
        done(null, response.accessToken);
      },
    });

    try {
      const userMailbox = selectedMailbox === 'Support' 
        ? 'support-pool@fraba.com' 
        : (selectedMailbox === 'Personal' ? userEmail : selectedMailbox);

      const message = await client.api(`/users/${userMailbox}/messages/${email.id}/$value`).get();
      
      const blob = new Blob([message], { type: 'message/rfc822' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${email.subject}.eml`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading email:', error);
    }
  };

  useEffect(() => {
    if (email) {
      fetchEmailContent();
      handleEmailStatusUpdate();
    }
  }, [email, instance, accounts, setLoading, selectedMailbox]);

  useEffect(() => {
    if (iframeRef.current && emailContent) {
        const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
        iframeDoc.body.innerHTML = emailContent;

        const textColor = getComputedStyle(document.documentElement).getPropertyValue('--text-color-2').trim();
        const bgColor = getComputedStyle(document.documentElement).getPropertyValue('--bg-color-1').trim();

        const styleElement = iframeDoc.createElement('style');
        styleElement.innerHTML = `
          body, body * { 
            color: ${textColor} !important; 
            background-color: ${bgColor} !important; 
          }`;
        iframeDoc.head.appendChild(styleElement);

        const links = iframeDoc.getElementsByTagName('a');
        for (let link of links) {
            link.setAttribute('target', '_blank');
            link.setAttribute('rel', 'noopener noreferrer');
        }
    }
  }, [emailContent]);

  useEffect(() => {
    fetchEmailContent();
  }, [theme]);

  return (
    <div className={`ed2-email-detail ${className}`}>
      {email ? (
        isLoading ? (
          <div className="ed2-email-loading">
            <div className="ed2-loading-spinner"></div>
          </div>
        ) : (
          <>
            <div className="ed2-email-container">
              <div className="ed2-email-header">
                <div className="ed2-email-subject">
                  {email.subject}
                </div>
                <FontAwesomeIcon 
                    icon={faDownload} 
                    onClick={handleDownloadEmail} 
                    title="Download Email" 
                    className="ed2-email-download-icon" 
                  />
              </div>
              <EmailDetailPersonnel 
                from={email.from} 
                toRecipients={email.toRecipients} 
                ccRecipients={email.ccRecipients} 
              />
              {attachments.length > 0 && (
                <EmailDetailAttachments 
                  attachments={attachments} 
                  onPreview={setPreviewAttachmentIndex} 
                />
              )}
            </div>
            <iframe ref={iframeRef} title="email-content" className="ed2-email-body-iframe" />
            {previewAttachmentIndex !== null && (
              <AttachmentPreview
                attachments={attachments}
                initialIndex={previewAttachmentIndex}
                onClose={() => setPreviewAttachmentIndex(null)}
              />
            )}
          </>
        )
      ) : (
        <div className="ed2-email-placeholder">Select an email to view details</div>
      )}
    </div>
  );
};

export default EmailDetail;
