import React, { useState, useEffect, useRef } from 'react';
import { useCodeMirror } from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import './AutoReplyTemplate.css';

const AutoReplyTemplate = ({ onClose }) => {
  const [autoReplyTemplate, setAutoReplyTemplate] = useState('');
  const editorContainer = useRef(null);

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/support/autoReplyTemplate`);
        const data = await response.json();
        setAutoReplyTemplate(data.template);
      } catch (error) {
        console.error('Error fetching auto-reply template:', error);
      }
    };
    fetchTemplate();
  }, []);

  const { view } = useCodeMirror({
    container: editorContainer.current,
    value: autoReplyTemplate,
    extensions: [javascript()],
    onChange: (value) => setAutoReplyTemplate(value),
    basicSetup: true,
  });

  const saveTemplate = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/support/autoReplyTemplate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ template: autoReplyTemplate }),
      });
      if (response.ok) {
        alert('Auto-reply template saved successfully');
        onClose();
      }
    } catch (error) {
      console.error('Error saving auto-reply template:', error);
    }
  };

  const exportAsHtml = () => {
    const blob = new Blob([autoReplyTemplate], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'auto-reply-template.html';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="se-ar-container">
      <div className="se-ar-content">
        <div className="se-ar-editor" ref={editorContainer} />
        <div className="se-ar-preview" dangerouslySetInnerHTML={{ __html: autoReplyTemplate }} />
      </div>
      <div className="se-ar-button-container">
        <button className="se-ar-save-button" onClick={saveTemplate}>
          Save Template
        </button>
        <button className="se-ar-export-button" onClick={exportAsHtml}>
          Export as HTML
        </button>
      </div>
    </div>
  );
};

export default AutoReplyTemplate;
