import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './EmailDetailPersonnel.css';

const EmailDetailPersonnel = ({ from, toRecipients, ccRecipients }) => {
  const [expanded, setExpanded] = useState(false);
  const [needsExpand, setNeedsExpand] = useState(false);
  const fromRef = useRef(null);
  const toRef = useRef(null);
  const ccRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      // 检查显示的内容是否都是邮箱地址
      const isAllEmails = (recipients) =>
        recipients?.every(recipient => recipient?.emailAddress?.address === recipient?.emailAddress?.name);

      const fromIsEmail = from?.emailAddress?.address === from?.emailAddress?.name;
      const toAllEmails = isAllEmails(toRecipients);
      const ccAllEmails = isAllEmails(ccRecipients || []);

      // 如果所有显示的都是邮箱地址，则不需要 expand 按钮
      setNeedsExpand(!(fromIsEmail && toAllEmails && ccAllEmails));
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [from, toRecipients, ccRecipients]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const renderEmailField = (label, recipients, ref) => (
    <div className="edp-email-field" style={{ flex: expanded ? '1 1 auto' : (ccRecipients && ccRecipients.length > 0 ? '1 1 33%' : '1 1 50%') }} ref={ref}>
      <strong>{label}: </strong> 
      <span className="edp-email-recipients">
        {recipients?.map(recipient => 
          expanded 
            ? (recipient?.emailAddress?.address || 'Unknown Address') 
            : (recipient?.emailAddress?.name || 'Unknown Name')
        ).join(', ') || `Unknown ${label} Recipients`}
      </span>
    </div>
  );

  return (
    <div className="edp-container">
      <div className={`edp-email-fields ${expanded ? 'expanded' : ''}`}>
        {renderEmailField('From', [from], fromRef)}
        {renderEmailField('To', toRecipients, toRef)}
        {ccRecipients && ccRecipients.length > 0 && renderEmailField('CC', ccRecipients, ccRef)}
      </div>
      <div className="edp-toggle-icon-container">
        {needsExpand && (
          <FontAwesomeIcon
            icon={expanded ? faChevronUp : faChevronDown}
            className="edp-toggle-icon"
            onClick={toggleExpand}
          />
        )}
      </div>
    </div>
  );
};

export default EmailDetailPersonnel;
