import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faExclamationCircle, faCircleArrowLeft, faIdBadge, faClipboardCheck, faArrowLeft, faLink } from '@fortawesome/free-solid-svg-icons';
import './EmailSupportTagSP.css';
import SeenByIndicator from './SeenByIndicator';
import FRABAStarIcon from './FRABA-STAR.png';

const NAME_ABBREVIATIONS = {
  'Luca Suen': 'LSU',
  'Delia Ana Gal': 'DGA',
  'Alexander Querfurth': 'AQE',
};

// 根据类别提取名字缩写的字符串
const getAbbreviationsStringFromCategories = (categories) => {
  const abbreviations = [];
  categories.forEach(category => {
    for (const name in NAME_ABBREVIATIONS) {
      if (category.includes(name)) {
        abbreviations.push(NAME_ABBREVIATIONS[name]);
      }
    }
  });
  return abbreviations.join(', ');
};

// 过滤出有效的“Seen by”类别（包含特定人名）
const filterValidSeenByCategories = (categories) => {
  return categories.filter(category => {
    return Object.keys(NAME_ABBREVIATIONS).some(name => category.includes(name));
  });
};

// 获取类别的缩写，主要用于"Assigned to", "Processing by"等类别的展示
const getCategoryAbbreviation = (category) => {
  for (const name in NAME_ABBREVIATIONS) {
    if (category.includes(name)) {
      return NAME_ABBREVIATIONS[name];
    }
  }
  return category.replace(/^(Processing by|Finished by|Assigned to|SR belongs to) /, '');
};

// 检查所有的"Seen by"是否包含特定的三个人（全部看过）
const hasAllSeenByCategories = (abbreviationsString) => {
  return ['LSU', 'DGA', 'AQE'].every(abbr => abbreviationsString.includes(abbr));
};

const EmailSupportTag = ({ hasAttachments, isRead, importance, categories, selectedFolder, from, toRecipients, subject, receivedDateTime }) => {

  // 分配类别
  const assignedCategory = categories.find(category => category.startsWith('Assigned to'));
  const processingCategory = categories.find(category => category.startsWith('Processing by'));
  const finishedCategory = categories.find(category => category.startsWith('Finished by'));
  const belongsToCategory = categories.find(category => category.startsWith('SR belongs to'));

  // 提取有效的"Seen by"类别并获取缩写
  const seenByCategories = filterValidSeenByCategories(categories.filter(category => category.startsWith('Seen by')));
  const seenByAbbreviations = getAbbreviationsStringFromCategories(seenByCategories);

  // 过滤掉不需要的类别
  const otherCategories = categories.filter(category => 
    !category.startsWith('Assigned to') && 
    !category.startsWith('Processing by') && 
    !category.startsWith('Finished by') &&
    !category.startsWith('Seen by') &&
    !category.startsWith('SR belongs to') &&
    category !== 'New' &&  // 排除New类别
    category !== 'Follow-Up' && // 排除Follow-Up类别
    category !== 'SP Reviewed'
  );

  // 检查邮件是否是内部邮件
  const isInternalEmail = selectedFolder.displayName === 'Inbox' || selectedFolder.displayName === 'Sent Items'
  ? from.emailAddress && from.emailAddress.address.includes('fraba.com') &&
    toRecipients && toRecipients.every(recipient => recipient.emailAddress.address.includes('fraba.com'))
  : false;

  // 检查是否是预订邮件
  const isBookingEmail = from.emailAddress && from.emailAddress.address === 'FRABASupportBooking@fraba.com';

  // 判断是否展示Seen标签（无Assigned, Processing, Finished类别，且所有人都看过）
  const hasSeenTag = !assignedCategory && !processingCategory && !finishedCategory && hasAllSeenByCategories(seenByAbbreviations);

  // 判断是否为自动回复邮件，检查标题是否以指定文本开头
  const isOutofOffice = subject && (
    subject.toLowerCase().startsWith('automatic reply:') || 
    subject.startsWith('Automatische Antwort:') ||
    subject.startsWith('Automatyczna odpowiedź:')
  );


  // 判断是否展示New标签
  const showNewTag = categories.includes('New') && !finishedCategory && !processingCategory &&!assignedCategory;

  // 判断是否展示Follow-Up标签，如果是自动回复邮件则不显示
  const showFollowUpTag = categories.includes('Follow-Up') && !isOutofOffice &&!assignedCategory && !finishedCategory;

  // 判断是否展示"SR belongs to"标签
  const showBelongsToTag = belongsToCategory && !assignedCategory && !processingCategory && !finishedCategory;

  // 判断是否展示"Done"标签
  const showDoneTag = (assignedCategory || finishedCategory) && !processingCategory && !showFollowUpTag;
  
  const showOngoingTag = processingCategory;

  const showDefaultOngoingTag = !showNewTag && !showDoneTag;
  // 确保只显示一次 Ongoing 标签
  const showSingleOngoingTag = showFollowUpTag || showOngoingTag || showDefaultOngoingTag;

  return (
    <div className="est-email-support-tag">
      <div className="est-left-tags">
        {showNewTag && (
          <div className="est-email-new-tag">
            New
          </div>
        )}

        {showSingleOngoingTag && (
          <div className="est-email-followup-tag">
            Ongoing
          </div>
        )}

        {showDoneTag && (
          <div className="est-email-done-tag">
            Done
          </div>
        )}


        {processingCategory && (
          <div className="est-email-processing-tag">
            <FontAwesomeIcon icon={faIdBadge} className="est-email-icon" />
            {getCategoryAbbreviation(processingCategory)}
          </div>
        )}
        {finishedCategory && (
          <div className="est-email-finished-tag">
            <FontAwesomeIcon icon={faClipboardCheck} className="est-email-icon" />
            {getCategoryAbbreviation(finishedCategory)}
          </div>
        )}
        {!processingCategory && assignedCategory && (
          <div className="est-email-support-assigned-tag">
            <FontAwesomeIcon icon={faArrowLeft} className="est-email-icon" />
            {getCategoryAbbreviation(assignedCategory)}
          </div>
        )}


        {isBookingEmail && (
          <div className="est-email-booking-tag">
            Booking
          </div>
        )}

        {showBelongsToTag && (
          <div className="est-email-belongs-to-tag">
            <FontAwesomeIcon icon={faLink} className="est-email-icon" />
            {getCategoryAbbreviation(belongsToCategory)}
          </div>
        )}
        {isOutofOffice && (
          <div className="est-email-automatic-reply-tag">
            <FontAwesomeIcon icon={faCircleArrowLeft} className="est-automatic-reply-icon" />
          </div>
        )}
        {hasAttachments && (
          <FontAwesomeIcon icon={faPaperclip} title="Attachment" className="est-attachment-icon" />
        )}
        {importance === 'high' && (
          <FontAwesomeIcon icon={faExclamationCircle} title="High Importance" className="est-importance-icon" />
        )}
        {isInternalEmail && (
          <img 
            src={FRABAStarIcon} 
            alt="Internal Email" 
            title="Internal Email" 
            className="est-internal-email-icon" 
          />
        )}
        {otherCategories && otherCategories.length > 0 && (
          <span className="est-email-categories">
            {otherCategories.join(', ')}
          </span>
        )}
      </div>
      {!isOutofOffice && seenByAbbreviations && (
        <div className="est-right-tags">
          <SeenByIndicator abbreviations={seenByAbbreviations} />
        </div>
      )}
    </div>
  );
};

export default EmailSupportTag;
