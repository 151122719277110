import React, { useState, useEffect, useRef } from 'react'; 
import { useMsal } from '@azure/msal-react';
import { Client } from '@microsoft/microsoft-graph-client';
import { motion } from 'framer-motion';
import './ComposeAddressBook.css';

const ComposeAddressBook = ({ onSelect, targetInput }) => {
  const { instance, accounts } = useMsal();
  const [searchQuery, setSearchQuery] = useState('');
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const inputRef = useRef(null);

  const searchCompanyContacts = async (query) => {
    if (!query.trim()) {
      setError('Search query cannot be empty.');
      setContacts([]);
      return;
    }

    setLoading(true);
    setError(null);
    const account = accounts[0];

    try {
      const response = await instance.acquireTokenSilent({
        scopes: ['User.Read', 'Contacts.Read', 'Directory.Read.All'],
        account: account,
      });

      const client = Client.init({
        authProvider: (done) => {
          done(null, response.accessToken);
        },
      });

      const result = await client
        .api('/users')
        .filter(`startswith(displayName, '${query}')`)
        .get();

      if (result && result.value && result.value.length > 0) {
        setContacts(result.value);
      } else {
        setContacts([]);
        setError('No contacts found.');
      }
    } catch (error) {
      console.error('Error fetching contacts:', error);
      setError('Failed to load contacts.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchQuery) {
        searchCompanyContacts(searchQuery);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleSelect = (contact) => {
    onSelect(contact.mail || contact.userPrincipalName);
  };

  return (
    <motion.div
      className="cab-compose-address-book"
      style={{ top: targetInput === 'cc' ? '125px' : '80px' }}
      initial={{ opacity: 0 }}   
      animate={{ opacity: 1 }}   
      exit={{ opacity: 0 }}      
      transition={{ duration: 0.15 }}  
    >
      <input
        ref={inputRef}
        type="text"
        placeholder="Search contacts..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      {loading && <div className="cab-loading-spinner"></div>}
      {error && <div className="cab-error-message">{error}</div>}
      <ul className="cab-contact-list">
        {contacts.length > 0 ? (
          contacts.map((contact) => (
            <li key={contact.id} onClick={() => handleSelect(contact)}>
              <span>{contact.displayName}</span>
              <span>{contact.mail || contact.userPrincipalName}</span>
            </li>
          ))
        ) : (
          !loading && !error && <div className="cab-no-results">No contacts found</div>
        )}
      </ul>
    </motion.div>
  );
};

export default ComposeAddressBook;
