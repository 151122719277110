import React, { useState, useEffect, useRef } from 'react';
import { OpenAI } from 'openai';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faTrash, faToggleOn, faToggleOff, faRobot } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
import { useMsal } from '@azure/msal-react';
import { Client } from '@microsoft/microsoft-graph-client';
import './Ai-AssistantApp.css';

const AiAssistantApp = ({ selectedEmail, selectedMailbox, userEmail }) => {
  const initialMessages = JSON.parse(localStorage.getItem('chatMessages')) || [{ role: 'system', content: 'You are chatting with AI Assistant powered by GPT-4o.' }];
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState(initialMessages);
  const [isLoading, setIsLoading] = useState(false);
  const [copilotMode, setCopilotMode] = useState(false);  // Copilot模式状态
  const chatBoxRef = useRef(null);
  const { instance, accounts } = useMsal();
  const [emailBody, setEmailBody] = useState('');
  const [loadingEmail, setLoadingEmail] = useState(false); // 用于指示邮件加载状态

  useEffect(() => {
    // 将消息保存到 localStorage
    localStorage.setItem('chatMessages', JSON.stringify(messages));
  }, [messages]);

  // 0.5秒延迟后自动滚动到最底部
  useEffect(() => {
    const timer = setTimeout(() => {
      if (chatBoxRef.current) {
        chatBoxRef.current.scrollTo({
          top: chatBoxRef.current.scrollHeight,
          behavior: 'smooth', // 平滑滚动
        });
      }
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  // 每次消息更新时自动滚动到最底部
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTo({
        top: chatBoxRef.current.scrollHeight,
        behavior: 'smooth', // 平滑滚动
      });
    }
  }, [messages]);

  useEffect(() => {
    // 当Copilot Mode 打开且selectedEmail变化时，加载邮件正文并发送给AI
    if (copilotMode && selectedEmail) {
      console.log('Copilot Mode is ON. Selected Email:', selectedEmail);
      console.log('Fetching email content for selectedEmail ID:', selectedEmail.id);
      fetchEmailContent(selectedEmail.id); // 通过selectedEmail.id加载正文
    }
  }, [selectedEmail, copilotMode]);

  const openai = new OpenAI({
    dangerouslyAllowBrowser: true,
    apiKey: 'sk-tWWwxI5WBXCjnDeNHDIs3MtT0bbScacGZU4cqyq3XlT3BlbkFJgAvGROxvT0pnt08ItewuKiwxBUDSqKVmKYa2ayGRAA',
  });

  const fetchEmailContent = async (emailId) => {
    if (!emailId || !selectedMailbox) {
      console.log('No emailId or selectedMailbox provided.');
      return;
    }

    setLoadingEmail(true);

    try {
      const account = accounts[0];
      console.log('Account:', account);
      const response = await instance.acquireTokenSilent({
        scopes: ['Mail.Read'],
        account: account,
      });

      console.log('Acquired token:', response.accessToken);

      const client = Client.init({
        authProvider: (done) => {
          done(null, response.accessToken);
        },
      });

      // 转换selectedMailbox值
      const userMailbox = selectedMailbox === 'Support'  
          ? 'support-pool@fraba.com' 
          : (selectedMailbox === 'Personal' ? userEmail : selectedMailbox);

      console.log('Fetching message from mailbox:', userMailbox);

      const message = await client.api(`/users/${userMailbox}/messages/${emailId}`).get();
      
      console.log('Message retrieved:', message);

      // 获取邮件正文
      const content = message.body.content;
      setEmailBody(content);
      sendProgressMessage(`🔍 Reading Email: ${selectedEmail.subject}`);
      console.log('Email body loaded:', content);

      // 触发Copilot Action
      handleCopilotAction(content);
    } catch (error) {
      console.error('Error fetching email content:', error);
      sendProgressMessage('⚠ Unable to load email content');
    } finally {
      setLoadingEmail(false);
    }
  };

  const handleCopilotAction = async (bodyContent) => {
    if (!bodyContent) return;
  
    setIsLoading(true);
  
    try {
      const prompt = `There are three things to do for the following email content:
        1. Summarize the email content and keep the information complete
        2. Give a possible brief proposal. The proposal is only given when you are sure of the specific answer. You are not required to provide a proposal on what kind of support we should provide.
        3. Summarize the encoder models, communication methods, and more short words that can be classified, such as: Profinet, Ethercat, communication for more than 1 month, communication for more than 1 week, etc. You can create some tags yourself.
        ${bodyContent}`;
      console.log('Sending email content to AI with prompt:', prompt);
  
      const response = await openai.chat.completions.create({
        model: 'gpt-4o',
        messages: [{ role: 'user', content: prompt }],
      });
  
      const assistantReply = response.choices[0].message.content;
      setMessages((prevMessages) => [...prevMessages, { role: 'assistant', content: assistantReply }]);
      console.log('AI reply:', assistantReply);
    } catch (error) {
      console.error('Error with GPT-4o API:', error);
  
      // 显示具体错误信息
      const errorMessage = error.message || 'An error occurred while communicating with AI.';
      setMessages((prevMessages) => [...prevMessages, { role: 'assistant', content: `Error: ${errorMessage}` }]);
    } finally {
      setIsLoading(false);
    }
  };
  

  // 发送进度消息到消息列表中
  const sendProgressMessage = (message) => {
    setMessages((prevMessages) => [...prevMessages, { role: 'assistant', content: message }]);
  };

  // 发送消息功能
  const handleSendMessage = async () => {
    if (!inputValue.trim()) return;
  
    const newMessage = { role: 'user', content: inputValue.trim() };
    const updatedMessages = [...messages, newMessage];
    setMessages(updatedMessages);
    setInputValue('');
  
    setIsLoading(true);
  
    try {
      const response = await openai.chat.completions.create({
        model: 'gpt-4o',
        messages: updatedMessages.map((msg) => ({ role: msg.role, content: msg.content })),
      });
  
      const assistantReply = response.choices[0].message;
      setMessages([...updatedMessages, assistantReply]);
    } catch (error) {
      console.error('Error with GPT-4o API:', error);
  
      // 显示具体错误信息
      const errorMessage = error.message || 'An error occurred while communicating with AI.';
      setMessages([...updatedMessages, { role: 'assistant', content: `Error: ${errorMessage}` }]);
    } finally {
      setIsLoading(false);
    }
  };
  

  // 清除聊天记录
  const clearHistory = () => {
    localStorage.clear();
    setMessages([{ role: 'system', content: 'Chat history cleared. Start a new conversation.' }]);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !isLoading && inputValue.trim()) {
      handleSendMessage();
    }
  };

  // 切换Copilot Mode
  const toggleCopilotMode = () => {
    setCopilotMode((prevMode) => !prevMode);
  };

  return (
    <div className="aias-container">
      <div className="aias-header">
      <button 
        className={`aias-toggle-button ${copilotMode ? 'aias-toggle-button-off' : ''}`} 
        onClick={toggleCopilotMode}>
        <FontAwesomeIcon icon={faRobot} className="fa-icon" />
        {copilotMode ? 'Turn off Copilot Mode' : 'Turn on Copilot Mode'}
      </button>


      </div>
      <div className="aias-chat-box" ref={chatBoxRef}>
        {messages.map((msg, index) => (
          <div key={index} className={`aias-message ${msg.role === 'user' ? 'aias-user-message' : 'aias-assistant-message'}`}>
            {msg.role === 'assistant' ? (
              <ReactMarkdown>{msg.content}</ReactMarkdown>
            ) : (
              msg.content
            )}
          </div>
        ))}
      </div>

      {isLoading && <div className="aias-loading">🤔 AI is thinking...</div>}
      {loadingEmail && <div className="aias-loading">⏳ Loading email content...</div>}

      <div className="aias-input-container">
        <input
          type="text"
          className="aias-input"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={handleKeyPress}  // 支持回车发送
          placeholder="Type a message..."
        />
        <button className="aias-send-button" onClick={handleSendMessage} disabled={isLoading || !inputValue.trim()}>
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
        <button className="aias-clear-button" onClick={clearHistory}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    </div>
  );
};

export default AiAssistantApp;
